<template>
  <div class="d-flex align-center flex-nowrap flex-grow-1 column-ref">
    <!-- {{iconName}} : {{valTy}} -->
    <icon-tooltip
      v-if="isDeleted"
      class="mr-2"
      color="error"
      :icon-name="'i.ExclamationThick'"
      :tooltip-text="$t('t.DocumentIsDeleted')"
    />
    <span class="nowrap text-truncate max-width-text text-transform-none">{{name}}</span>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  inheritAttrs: false,
  computed: {
    isDeleted () {
      return this.cache?.doc?.isDeleted ?? false
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.NewColumn') ?? this.$t('t.None')
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.MainPresetRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String
  }
}
</script>
